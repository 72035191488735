import React from "react";
import './Footer.css';


const Footer = () => {
  return (
    <div className="FooterContainer">
          

      <div className="ContentContainer">
        <p className="PFooter">
          &copy;{new Date().getFullYear()} Alquimista 3D SAS. All rights reserved
        </p>
      </div>
    </div>
  );
};

export default Footer;
