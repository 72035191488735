import React from "react";
import "./Modeling.css";
import Dragon from './images/Dragon2.gif'
import Carousel from "./Carousel";


const Modeling = () => {
  return (
    <section id="Modeling" >
      <div className="StructureWrap">
        <div className="structure">
          <div className="TextContainer">
            <h4 >Our Services</h4>
            <h1 className="H1Modeling">
              From the simplest to the most complex shapes
            </h1>
            <h4 >3D modeling</h4>
          </div>
          <div className="imageWrap">
            <img src={Dragon} alt="Dragon Modeling" className="DragonGif" />

            <h4 className="weText">
              We are a highly talented team that will bring your ideas to reality
            </h4>
          </div>

        </div>
        <Carousel/>
        <h1 className="weWork">We work too fast!</h1>
        <a className="portfolio" target="_blank" href="https://www.instagram.com/3d.alquimista/?igshid=MTIzZWMxMTBkOA%3D%3D"> Check our work</a>

      </div>

    </section>
  );
};

export default Modeling;