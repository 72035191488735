import React from "react";
import "../Contact.css";
import { useRef } from "react";
import emailjs from '@emailjs/browser';
import whatsapp from "../images/Whatsapp.svg";
import email from "../images/mail.svg";
import instagram from "../images/instagram.svg";

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_1fqgua5",
        "template_fikyurs",
        form.current,
        "On2oxqLbUhLjK89Ff"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };
  return (
    <section id="Contact" className="ContactWrap">
     <div className="ContactTitleContainer">
     <h3 className="H2Text">Contáctanos!</h3>
          <p >
          Somos un grupo multidisciplinario compuesto por diseñadores, ingenieros y joyeros calificados, quienes estarán siempre dispuestos a ayudarte a materializar tus ideas.
          </p>
        </div>
      <div className="ContentContainer">
       
        <div className="ContactContainer">
          <div className="cCardsContainer">
            
            <div className="ContactCard">
              <a
                href="https://wa.me/573053600565?text=Hola%20estoy%20interezad@%20en%20sus%203D%20servicios"
                className="Whatsappli"
                target="_blank"
              >
                <img
                  src={whatsapp}
                  alt="Whatsapp icon"
                  className="contactIcon"
                />
                <h4 >whatsapp</h4>
              </a>
            </div>

            <div className="MailWrap">
              <div className="mailContentWrap">
                <img src={email} alt="Email icon" className="contactIcon" />
                <h4 >3dalquimista@gmail.com</h4>
              </div>
            </div>

            <div className="ContactCard">
              <a
                href="https://www.instagram.com/joyeria.3d/?utm_source=ig_web_button_share_sheet&igshid=MmVlMjlkMTBhMg=="
                className="Whatsappli"
                target="_blank"
              >
                <img
                  src={instagram}
                  alt="instagram icon"
                  className="contactIcon"
                />
                <h4 className="H4Text">@3d.alquimista</h4>
              </a>
            </div>
          </div>

          <form ref={form} onSubmit={sendEmail} className="contactForm">
            <input type="text" name="name" placeholder="Nombre" required  autocomplete="given-name"/>
            <input
            id="email"
              type="email"
              name="email"
              placeholder="Email"
              autocomplete="email"
              required
            />
            <textarea
              name="message"
              id="message"
              placeholder="Mensaje"
              cols="30"
              rows="10"
              required
            ></textarea>
            <button type="submit" className="btn">
              Enviar
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Contact;

