import React from 'react';
import Contact from '../Componentes-Es/ContactEs';
import Footer from '../Componentes-Es/FooterEs';



const Contacto = () => {

    return ( 
        <section id='Contacto' >
            <Contact/>
            <Footer/>
        </section>
     );
}
 
export default Contacto;