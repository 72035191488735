import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../images/logo.svg';
import Menu from '../images/Menu.svg';

import './NavBarv2.css';

function NavBar() {
    const [sidebar, setSidebar] = useState(false);
    const showSidebar = () => setSidebar(!sidebar);


    return (
        <div className='NavBar-contaner'>
            <div className="linksContainers">
                <nav className="NavPages">
                    <Link to="../Es" className='LogoLink'>
                        <img
                            src={Logo}
                            alt="logo"
                            className="NavLogo"
                        />

                    </Link>
                    <Link to="/es">Inicio</Link>
                    <Link to="/es/BlueCast" >BlueCast</Link>
                    <Link to="/es/Contacto">Contacto</Link>
                </nav>
                <nav className="NavLan">
                    <p>Lenguaje:</p>
                    <Link to="../En" >
                        En
                    </Link>
                    <Link to="../Es" >
                        Es
                    </Link>
                </nav>


            </div>
            <div className="dropDownNav">
            <img
                        src={Logo}
                        alt="logo"
                        className="NavLogo"
                    />
                <div className='LogoToggle' onClick={showSidebar} >
                    <img src={Menu} alt="Menu Icon" className="MenuIcon" value={{color:'#FFF'}} />
                </div>
                <div className={sidebar ? 'NavDropWrapActive' : 'NavDropWrap'}>
                    <nav className="NavPagesDrop">
                        <Link to="/es" onClick={showSidebar}>Inicio</Link>
                        <Link to="/es/BlueCast" onClick={showSidebar}>BlueCast</Link>
                        <Link to="/es/Contacto" onClick={showSidebar}>Contacto</Link>
                    </nav>
                    <nav className="NavLanDrop">
                        <p>Lenguajes:</p>
                        <Link to="../En" onClick={showSidebar} >
                            En
                        </Link>
                        <Link to="../Es"  onClick={showSidebar}>
                            Es
                        </Link>
                    </nav>
                </div>
            </div>
        </div>
    );
}

export default NavBar;