// Carousel.js
import React from 'react';
import './Carousel.css';

import image2000 from './images/images-carrousel/2000.png';
import engagementRing from './images/images-carrousel/EngagementRing.png';
import heart from './images/images-carrousel/Heart.png';
import santos from './images/images-carrousel/Santos.png';
import twistedEarring from './images/images-carrousel/TuistedEarring.png';
import devilEmoji from './images/images-carrousel/devilEmoji.png';
import engineRing from './images/images-carrousel/engineRing.png';
import greenChain from './images/images-carrousel/greenChain.jpg';
import spineBracelet from './images/images-carrousel/spineBracelet.png';

const imagesData = [
  { id: 1, title: '2000 Pendant', imageUrl: image2000 },
  { id: 2, title: 'Engagement Ring', imageUrl: engagementRing },
  { id: 3, title: 'Voronoi Heart', imageUrl: heart },
  { id: 4, title: 'Santos Modified', imageUrl: santos },
  { id: 5, title: 'Twisted Earring', imageUrl: twistedEarring },
  { id: 6, title: 'Devil Emoji Pendant', imageUrl: devilEmoji },
  { id: 7, title: 'Engine Ring', imageUrl: engineRing },
  { id: 8, title: 'Green Chain', imageUrl: greenChain },
  { id: 9, title: 'Pablo Forsteri Spine Bracelet', imageUrl: spineBracelet },
  // Add more images as needed
];

const Carousel = () => {
  
    
  return (
    <div className="carousel-container">
      <div className="carousel" 
    >
        {imagesData.slice(0, 9).map((image) => (
          <div
            key={image.id}
            className="carousel-card"
            style={{ backgroundImage: `url(${image.imageUrl})` }}
          >
            <p>{image.title}</p>
          </div>
        ))}
      </div>
    </div>
  );
        }

export default Carousel