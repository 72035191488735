import React from "react";
import "../Design.css";
import designIllustration from '../images/designillustration.svg';

const Design = () => {
  return (
    <section id="Design" className="DesignContainer">
      <div className="DesignContentContainer">
        <div className="DesignWrap">
          <div className="DesignTextContainer">
            <h4 >Diseño</h4>
            <h3 className="H" >
            ¿Sabes cuál será tu próxima colección?           </h3>
            <h4 >
            Tenemos un grupo de expertos que diseñaran tú ideas de manera muy atractiva, manteniendo la visión de la marca y los gustos de la audiencia, para incrementar las ventas y mejorar el Engagement con el público.
            </h4>
          </div>
          <div className="IllustrationWrap">
          <img src={designIllustration} alt="Design illustration" className="DesignIllustration" />

          </div>
        </div>
      </div>
    </section>
  );
};

export default Design;
