import React from "react";
import "./Hero.css";
import Corazon from "./Corazon";


function Hero() {
  return (
    <div  className="wrap">
      <Corazon />
    </div>
  );
}

export default Hero;
