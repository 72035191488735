import React from 'react';
import Hero from '../Hero';

import './Home.css'
import Modeling from '../Componentes-Es/ModelingEs';
import Rendering from '../Componentes-Es/RenderingEs';
import Animation from '../Componentes-Es/AnimationEs';
import Design from '../Componentes-Es/DesignEs';
import Footer from '../Componentes-Es/FooterEs';
import The3djewel from '../Componentes-Es/The3dJewelEs';


const Home = () => {
    return ( 
        <section id='Home'>
            <Hero/>
            
            <div className='HomeInfo'>
               
                
            <The3djewel/>
           <Modeling/>
           <Rendering/>
           <Animation/>
           <Design/>
           <Footer/>

            </div>

        

        </section>
     );
}
 
export default Home;