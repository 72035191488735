import React from 'react'


import { Routes, Route, } from 'react-router-dom';
import Contacto from './Contacto'
import Home from './Casa';

import BlueCast from './BlueCast';
import NavBar from '../INavBar/NavBarEs';


const Es = () => {
    return ( 

        <>
        <NavBar/>
          <div className='En'>
            <Routes>
           
            <Route exact path="/" element={<Home/>} />
            <Route exact path="/Contacto" element={<Contacto />} />
            <Route exact path="/BlueCast" element={<BlueCast/>} />
            </Routes>

        </div>
        </>
      
        
     );
}
 
export default Es;