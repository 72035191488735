import React from "react";
import "./Design.css";
import designIllustration from './images/designillustration.svg';

const Design = () => {
  return (
    <section id="Design" className="DesignContainer">
      <div className="DesignContentContainer">
        <div className="DesignWrap">
          <div className="DesignTextContainer">
            <h4 >Design</h4>
            <h3 className="H" >
            Do you know what will be the next collection?            </h3>
            <h4 >
              We have a team of experts tha will design your ideas in a very
              attractive way, keeping your auidience and your brand vision to
              increase the sales of your company and improve the engagement
            </h4>
          </div>
          <div className="IllustrationWrap">
          <img src={designIllustration} alt="Design illustration" className="DesignIllustration" />

          </div>
        </div>
      </div>
    </section>
  );
};

export default Design;
