import React from 'react';
import Contact from '../Contact';
import Footer from '../Footer'



const ContactP = () => {

    return ( 
        <section id='ContactP' >
            <Contact/>
            <Footer/>
        </section>
     );
}
 
export default ContactP;