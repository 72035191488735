import React from "react";
import "../Modeling.css";
import Dragon from '../images/Dragon2.gif'
import CarouselEs from "./CarouselEs";


const Modeling = () => {
  return (
    <section id="Modeling" >
      <div className="StructureWrap">
        <div className="structure">
          <div className="TextContainer">
            <h4 >Nuestros Servicios</h4>
            <h1 className="H1Modeling">
            Desde la forma más simple, hasta la más compleja.
            </h1>
            <h4 >Modelado 3D</h4>
          </div>
          <div className="imageWrap">
            <img src={Dragon} alt="Dragon Modeling" className="DragonGif" />

            <h4 className="weText">
            Somos un talentoso equipo que traerá a la realidad tus ideas.
            </h4>
          </div>

        </div>
        <CarouselEs/>
        <h1 className="weWork">¡Trabajamos muy Rápido!</h1>
        <a className="portfolio" target="_blank" href="https://www.instagram.com/3d.alquimista/?igshid=MTIzZWMxMTBkOA%3D%3D"> Check our work</a>

      </div>

    </section>
  );
};

export default Modeling;