import React,  { useEffect, useState } from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';

import En from './components/Pages/En';
import Es from './components/Pages/Es';
import './App.css'




const App = () => {
  const [userLanguage, setUserLanguage] = useState('en');

useEffect(() => {
  const preferredLanguage = navigator.language.substr(0, 2);
  setUserLanguage(preferredLanguage);
}, []);
  return (
    <Router>
      <div className="App">
      
          <Routes>
          <Route path="*" element={<En />} />
          <Route path="/en/*" element={<En />} />
          <Route path="/es/*" element={<Es />} />
        </Routes>
        
        
        
      </div>
    </Router>
  );
};

export default App;
