import React from 'react';
import '../The3dJewel.css';



const The3djewel = () => {
    return ( 
    <div className="the3dwrap">
        <div className="T3jtextContainer">
            
        <h1>The 3D Jewel</h1>
        <p>Obtén acceso a toda nuestra librería por medio de 1 sola subscripción. </p>
        <p>- Nuevos diseños cada semana.</p>
        <p>- Todos los archivos listos para imprimir.</p>
        <p>- Imágenes y animaciones.</p>
        <p>- Royalty Free</p>
       
        
        <div className="t3j-a-Container">
            <a href="https://www.patreon.com/the3djewel" target='blank'> LIBRERÍA</a>
            <a href="https://www.the3djewel.com" target='blank'>SITIO WEB</a>
            </div>


        

        </div>
      

    </div> 
    );
}
 
export default The3djewel;