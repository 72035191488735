import React from "react";
import "./Animation.css";
import Animacion from "./images/Animacion.gif";


const Animation = () => {
  return (
    <section id="Animation" className="AnimationContainer">
      <div className="AnimationCard">
        <div className="ContentContainer">
        <div className="AllContetWrap">
          <div className="ATextWrap">
          <h4>Animation</h4>
            <h1 className="H2Animation">Bring your ideas to life!</h1>
            <h4 className="H4Text">
            Show to your costumers your work from different angles, let them see the nature of your collections with epic shortcuts.
            </h4>
          </div>

          <div className="AnimationWrap">
            <img
              src={Animacion}
              alt="animation"
              className="AnimationGif"
              loading="lazy"
            />
           
            
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Animation;
