import React from 'react';
import './The3dJewel.css';



const The3djewel = () => {
    return ( 
    <div className="the3dwrap">
        <div className="T3jtextContainer">
            
        <h1>The 3D Jewel</h1>
        <p>Get access to our entire library through a Subscription.</p>
        <p>- New designs every week.</p>
        <p>- All files ready to Print.</p>
        <p>- Pictures and Animations. </p>
        <p>- Royalty Free</p>
       
        
        <div className="t3j-a-Container">
            <a href="https://www.patreon.com/the3djewel" target='blank'>LEARN MORE</a>
            <a href="https://www.the3djewel.com" target='blank'>GO TO THE WEBSITE</a>
            </div>


        

        </div>
      

    </div> 
    );
}
 
export default The3djewel;