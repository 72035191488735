import React from "react";
import "../Rendering.css";
import Ring1 from "../images/Ring1.png";
import Ring2 from "../images/Ring2.png";
import Ring3 from "../images/Ring3.png";
import Ring4 from "../images/Ring4.png";

const Rendering = () => {
  return (
    <section id="Rendering" className="RenderingContainer">
      
        <div className="RenderingWrap">
          
          <h4>Renderizado de Joyería</h4>
          <div className="RImageContainer">
            <img src={Ring1} alt="RingVisualization1" className="Render" />
            <img src={Ring2} alt="RingVisualization2" className="Render" />
            <img src={Ring3} alt="RingVisualization3" className="Render" />
            <img src={Ring4} alt="RingVisualization4" className="Render" />
          </div>
          <div className="RenderingTextWrap">
            <h4 >
            Muestra la perfección de tus joyas y publícalas en redes sociales, páginas web y avisos publicitarios.
            </h4>
          </div>
        </div>
      
    </section>
  );
};

export default Rendering;