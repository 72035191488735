import React from 'react';
import Hero from '../Hero';
import Modeling from '../Modeling';
import Rendering from '../Rendering';
import Design from '../Design';
import Animation from '../Animation';
import Footer from '../Footer';
import './Home.css'
import The3djewel from '../The3dJewel';


const Home = () => {
    return ( 
        <section id='Home'>
            <Hero/>
            
            <div className='HomeInfo'>
                
                
            <The3djewel/>
            <Modeling/>
            <Rendering/>
            <Animation/>
            <Design/>
            <Footer/>
            </div>

        

        </section>
     );
}
 
export default Home;