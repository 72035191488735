import React from 'react';

import { Routes, Route } from 'react-router-dom';
import ContactP from './ContactP';
import Home from './Home';
import NavBar from '../INavBar/NavBar';

const En = () => {
  return (
    <>
      <NavBar />
      <div className='En'>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/ContactP" element={<ContactP />} />
        </Routes>
      </div>
    </>
  );
};

export default En;
